.help-logo {
  margin: 0 .7rem;

  .img-wrapper {
    height: 40px;
  }

  img {
    width: 40px;
  }
}