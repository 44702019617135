@import 'bootstrap/scss/bootstrap-grid.scss';
@import '../styles/mixins.scss';
@import '../styles/theme.scss';
@import '../styles/variables.scss';

.top-navigation {

  header {
    background-color: $background_color;
    box-shadow: none;

    @include media-breakpoint-down(sm) {
      background-color: $secondary_color;
    }
  }

  .top-navigation-container {
    border-bottom: 1px solid nth($secondary_colors, 7);
    display: flex;
    height: $header_height;

    .top-navigation-panel-before {
      height: 2rem;
      background-color: $header_background;
      width: 100%;
      position: absolute;
      z-index: 0;
    }

    .top-navigation-panel {
      margin: .2rem .2rem;
      z-index: 1;

      &.left {
        flex: 1 0 auto;
        @include flex-center;

        .icon-container {
          @include media-breakpoint-down(sm) {
            margin-bottom: 1.6rem;

            &:hover {
              background-color: rgba($background_color, $alpha: 0.3);
            }
          }

          svg {
            color: $primary_color;

            @include media-breakpoint-down(sm) {
              color: $background_color;
            }
          }
        }
      }

      &.center {
        flex: 1 1 100%;
        @include flex-left;

        @include media-breakpoint-down(sm) {
          @include flex-center;
        }

        .logo-main-container {
          display: flex;
        }

        .logo-container {
          height: 88px;

          @include media-breakpoint-down(sm) {
            margin-right: 1rem;
          }
        }

        .title-container {
          align-self: flex-end;
          font-size: x-large;
          font-weight: 600;
          margin-left: .5rem;
          padding-bottom: 2px;

          @include media-breakpoint-down(sm) {
            color: $background_color;
            margin-right: 3.1rem;
            margin-left: 0;
          }
        }
      }

      &.right {
        flex: 1 0 auto;
        @include flex-center;
        align-items: flex-end;
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }
}
