@import 'bootstrap/scss/bootstrap-grid.scss';
@import '../../styles/mixins.scss';
@import '../../styles/theme.scss';
@import '../../styles/variables.scss';

$doc_panel_width: 22.5rem;
$button_width: 3rem;
$button_margin: 0.5rem;
$title_margin: 1rem;
$title_width: calc(100% - #{$button_width + $button_margin + 2*$title_margin});

.document-panel {
  width: $doc_panel_width;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  .document-panel-paper {
    width: $doc_panel_width;
    background-color: nth($secondary_colors, 7);

    @include media-breakpoint-down(sm) {
      width: 100%;
      background-color: $background_color;
    }

    .document-panel-header {
      height: $header_height;
      background-color: nth($secondary_colors, 5);
      display: flex;
      align-items: center;

      .document-panel-header-title {
        margin: 0 $title_margin;
        width: $title_width;

        .document-panel-header-title-text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .document-panel-content {
      padding: $element_default_margin;

      .progress-container {
        text-align: center;

        .progress {
          color: $primary_color;
        }
      }

      .fields-containers>* {
        margin-bottom: $element_default_margin;
      }

      .error {
        color: $accent_color_red;
        font-size: 16px;
        font-family: SourceSansPro-Regular;
        font-weight: 400;
      }
    }
  }
}
