@import 'bootstrap/scss/bootstrap-grid.scss';
@import './mixins.scss';
@import './theme.scss';
@import './variables.scss';

body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: SourceSansPro-Regular;
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: SourceSansPro-Bold;
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/SourceSansPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
    url(../fonts/MaterialIcons-Regular.woff) format('woff'),
    url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: $icon_width;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

@include media-breakpoint-up(md) {
  .mobile-only {
    display: none !important;
  }

  .desktop-only {
    display: block;
  }
}


@include media-breakpoint-down(sm) {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }
}