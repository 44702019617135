@import 'bootstrap/scss/bootstrap-grid.scss';
@import '../../styles/theme.scss';
@import '../../styles/variables.scss';

$floor_link_padding: 0.7rem;

.om-house {
  font-size: 0.75rem;
  color: nth($secondary_colors, 2);

  a {
    text-decoration: none;
  }

  .row {
    width: 100%;

    &.floor2 {
      justify-content: center;

      .col {
        margin-bottom: 0.75rem;
        padding: 0;

        a {
          text-decoration: underline;
        }
      }
    }

    &.om-bordered {
      border-right: 2px solid nth($secondary_colors, 5);
      border-left: 2px solid nth($secondary_colors, 5);
      
      &.om-bordered-bottom {
        border-bottom: 2px solid nth($secondary_colors, 5);
      }
      
      &.om-bordered-top {
        border-top: 2px solid nth($secondary_colors, 5);
      }
    }
  }

  .floor-link {
    margin: 0.75rem 0;
    display: block;
    padding: 0 $floor_link_padding;

    &.subtitle {
      padding: 0;

      .floor-link-text {
        color: $secondary_color;
        font-family: SourceSansPro-Bold;
        color: nth($secondary_colors, 3);
        text-transform: uppercase;
      }
    }
  }

  .bg-l {
    width: 100%;
  }

  .icon-grp {
    cursor: pointer;

    .row {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }

  .col-md-20-100{
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@include media-breakpoint-down(sm) {
  .om-house {
    text-align: left;

    div[class^="col"] {
      padding-left: 0;
      padding-right: 0;
      
      &.om-spacer {
        display: none;
      }
    }

    .row {
      margin: 0;

      &.floor2 {

        .col {
          margin: 0;
        }
      }

      &.om-bordered {
        border: 0px;
      }
    }

    .accordion-summary {
      .house-header-title-text {
        font-size: 1.625rem;
        color: $secondary_color;
      }

      .icon-container {
        padding: 0;
        font-size: $icon_width;
        color: $primary_color;
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    .accordion-details {
      .single-node {
        width: 100%;
        background-color: nth($secondary_colors, 8);

        .house-header-title {
          padding: 0;
          display: flex;
          align-items: center;
          padding: 0 $floor_link_padding;
          height: 3rem;
        }
      }
    }

    .bg-l {

      &>.floor2-item {
        background-color: nth($secondary_colors, 8);
        margin-bottom: 0.5rem;
        height: 3rem;
      }
    }

    .icon-grp {
      background-color: nth($secondary_colors, 8);
      margin-bottom: 0.5rem;

      .row {

        div {
          &:nth-child(1) {
            flex: 0 0 3rem;
            max-width: 3rem;
            height: 3rem;
            text-align: center;

            img {
              width: 1.75rem;
              height: 1.75rem;
              margin-top: 0.625rem;
            }
          }

          &:nth-child(2) {
            font-family: SourceSansPro-Regular;
            font-size: 1rem;
            color: $secondary_color;
            flex: 0 0 calc(100% - 3rem);
            max-width: calc(100% - 3rem);
            margin-top: 0.3rem;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {

  .om-house {
    text-align: center;

    div[class^="col"] {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }

    .row {
      margin: 0 -0.25rem;

      &.floor2 {
  
        a.house-header-link {
          text-decoration: none;
        }
  
        .house-header-title {
            margin: 0.5rem 0 0 0;
        }
      }
    }

    .house-header-title {
      margin: 1.6rem 0;
      height: initial;
      padding-top: 0rem;

      &::after {
        content: none;
      }

      .house-header-title-text {
        color: $secondary_color;
        font-family: SourceSansPro-Bold;
        font-size: 1.525rem;
        font-weight: normal;
      }
    }

    .bg-l {
      background-color: nth($secondary_colors, 8);

      .floor2-item {
        background-image: url('../../images/house/om-house-arrow.svg');
        background-position-x: right;
        background-position-y: center;
        background-size: 0.785rem 1.75rem;
        background-repeat: no-repeat;
        flex-basis: auto;
        max-width: unset;
        width: auto;

        &:last-child {
          background-image: none;
        }

        .floor-link {
          padding: 0 $floor_link_padding*3 0 $floor_link_padding*1.5;
        }
      }

      .floor-link {
        margin-left: 0;

        .floor-link-text {
          color: $secondary_color;
          font-weight: 600;
        }
      }
    }

    .bg-d {
      background-color: nth($secondary_colors, 2);
      color: $background_color;
      margin-top: 0.25rem;

      .house-header-title {

        .house-header-title-text {
          color: $background_color;
        }
      }
    }

    .house-roof {
      background-image: url('../../images/house/om-house-roof.svg');
      background-size: 100% 7.5rem;
      background-repeat: no-repeat;
      height: 7.5rem;

      .col {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .house-header-link {
          align-self: center;

          .house-header-title-text {
            color: $secondary_color;
            padding-top: 25px;
          }
        }
      }
    }

    .icon-grp {

      .row {
        display: block;
        text-decoration: none;

        div {
          &:nth-child(1) {
            height: 4.5rem;
            flex: 0 0 100%;
            max-width: 100%;

            img {
              width: 4.5rem;
              height: 4.5rem;
              margin-top: 0;
            }
          }

          &:nth-child(2) {
            flex: 0 0 100%;
            max-width: 100%;
            font-family: SourceSansPro-Bold;
            font-size: 1rem;
            line-height: 1.15rem;
            color: $secondary_color;
            height: 3.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 0.3rem;
          }
        }
      }
    }
  }
}
