@import '../../../styles/theme.scss';
@import '../../../styles/variables.scss';

.chips-wrapper {
  display: flex;
  flex-wrap: wrap;

  .chip-wrapper {
    margin: $element_flex_margin;

    .clear-button {
      background-color: $claim_on_white_color;
      color: $background_color;
    }
  }
}
