@import '../../styles/theme.scss';

.open-house-button {
  margin: .6rem auto !important;
  background-color: $primary_color !important;
  color: $background_color !important;
  font-size: 1rem !important;
  width: 8rem !important;

  &:hover {
    background-color: nth($primary_colors, 2) !important;
  }
}
