$header_height: 5.9rem;

$header_mobile_height: 7.5rem;

$menu_width: 15.2rem;

$menu_collapsed_width: 3.5rem;

$icon_width: 1.5rem;

$menu_icon_maring: ($menu_collapsed_width - $icon_width) / 2;

$refiner_width: 11rem;

$element_default_margin: 1rem;

$mobile_margin: $element_default_margin/2;

$element_flex_margin: 0 $element_default_margin $element_default_margin 0;

$font_name_material-icons: "MaterialIcons-Regular";

$font_path_material-icons: "../fonts";

$font_file_name_material-icons: "MaterialIcons-Regular";

$font_weight_material-icons: normal;

$font_style_material-icons: normal;

$font_exts_material-icons: woff, woff2, ttf;
