.taxonomy-selector-container {

  .expand-button {
    display: none;
  }
}

.taxonomy-select-option {

  &.collapsed {
    display: none;
  }

  .expand-button {
    padding: 0;
    margin-right: 0.625rem;

    &.button-hidden {
      visibility: hidden;
    }
  }
}

