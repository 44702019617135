@font-face {
  font-family: Implenia-Icons;
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Implenia-Icons.woff) format('woff'),
  url(../fonts/Implenia-Icons.ttf) format('truetype');
}

.icon {
  font-family: Implenia-Icons;
}

.icon-Menu:before {
  @extend .icon;
  content: "\e900";
}

.icon-Arrow-Downward:before {
  @extend .icon;
  content: "\e901";
}

.icon-Arrow-Upward:before {
  @extend .icon;
  content: "\e902";
}

.icon-Arrow-Right:before {
  @extend .icon;
  content: "\e903";
}

.icon-Arrow-Left:before {
  @extend .icon;
  content: "\e904";
}

.icon-Arrow-Drop-Up:before {
  @extend .icon;
  content: "\e905";
}

.icon-Arrow-Drop-Down:before {
  @extend .icon;
  content: "\e906";
}

.icon-Close:before {
  @extend .icon;
  content: "\e907";
}

.icon-Cancel-Circle:before {
  @extend .icon;
  content: "\e908";
}

.icon-Done:before {
  @extend .icon;
  content: "\e909";
}

.icon-Sort:before {
  @extend .icon;
  content: "\e90a";
}

.icon-Customer-Satisfaction:before {
  @extend .icon;
  content: "\e90b";
}

.icon-OM-House:before {
  @extend .icon;
  content: "\e90c";
}

.icon-Predefined-Filter:before {
  @extend .icon;
  content: "\e90d";
}

.icon-Processes:before {
  @extend .icon;
  content: "\e90e";
}

.icon-Projects:before {
  @extend .icon;
  content: "\e90f";
}

.icon-Search:before {
  @extend .icon;
  content: "\e910";
}
