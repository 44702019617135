.predefined-filter-page-wrapper {
  display: flex;
  margin: 0 auto;
  justify-content: center;

  .predefined-filter-page-container {
    flex-direction: column;
    display: flex;

    .page-title {
      margin-bottom: 2rem;
    }

    .filters-container {
      flex-direction: column;
      display: flex;

      .taxonomy-selector-container {
        width: 18.8rem;
        margin: .3rem;
      }
    }
  }
}
