@import '../../styles/theme.scss';
@import '../../styles/variables.scss';

.breadcrumb {
  color: $primary_color;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: $element_default_margin;

  .breadcrumb-house-link {
    color: $primary_color;
    text-decoration: none;
    font-size: $icon_width;
    line-height: $icon_width;
  }

  .breadcrumb-arrow {
    margin: 0 $icon_width/4;
    line-height: $icon_width;
  }

  a {
    text-decoration-color: black;
  }
}
