@import '../../styles/mixins.scss';

.current-user-info {
  @include flex-center;
  height: 40px;

  .user-name {
    margin: 1px .7rem 0 .7rem;
  }
}
