@import 'bootstrap/scss/bootstrap-grid.scss';
@import '../../../styles/theme.scss';
@import '../../../styles/variables.scss';

.nothing-found-text {
  text-align: center;
}

.documents-table {
  word-break: break-all;
  box-sizing: border-box;

  .document-row {
    height: 3rem;

    &.selected {
      background-color: nth($secondary_colors, 6);

      .document-info-button {
        visibility: visible;
      }
    }

    &:hover {
      background-color: nth($secondary_colors, 7);

      .document-info-button {
        visibility: visible;
      }
    }
  }

  .cell {
    box-sizing: border-box;
    padding: 0.25rem;
    word-break: break-word;
  }

  .document-icon-cell {
    @extend .cell;
    width: 4rem;

    .document-icon {
      width: $icon_width;
      height: $icon_width;
    }
  }

  .document-title-cell {
    @extend .cell;

    .document-title-link {
      color: $primary_color;
      text-decoration: none;

      .document-title-link-text {
        display: inline-block;
      }
    }

    .document-title-cell-content {
      display: none;
    }
  }

  .document-type-cell {
    @extend .cell;
    min-width: 8rem;
  }

  .document-tags-cell {
    @extend .cell;
    min-width: 3rem;
  }

  .document-language-cell {
    @extend .cell;
    min-width: 5rem;
  }

  .document-info-cell {
    @extend .cell;
    text-align: center;
    padding-right: 0;

    &>* {
      vertical-align: middle;
    }

    .document-info-button {
      visibility: hidden;
    }
  }

  @include media-breakpoint-down(sm) {

    .document-row {
      height: 3.5rem;
    }

    .documents-table-head,
    .document-type-cell,
    .document-tags-cell,
    .document-language-cell {
      display: none;
    }

    .document-icon-cell {
      width: $icon_width;
    }

    .document-info-cell {

      .document-info-button {
        visibility: visible;
        padding: 0.25rem;
      }
    }

    .document-title-cell {

      .document-title-link {
        color: $secondary_color;

        .document-title-link-text {
          display: block;
        }
      }

      .document-title-cell-content {
        display: block;

        .document-languages-text {
          color: nth($secondary_colors, 2);
          display: inline-block;
        }

        .document-directive-text {
          @extend .document-languages-text;
          margin-right: 0.5rem;
        }
      }
    }
  }
}