@import 'bootstrap/scss/bootstrap-grid.scss';
@import '../../../styles/variables.scss';

.documents-search-results-container {

  .buttons-wrapper {
    display: flex;
  }

  @include media-breakpoint-down(sm) {

    .filter-chips-wrapper {
      width: calc(100% - 48px);
    }

    .not-all-messages {
      margin-bottom: $element_default_margin;
    }
  }
}
