@import '../../../styles/theme.scss';

.sort-menu-wrapper {

  ul.sort-menu {
    padding-top: 0;

    .sort-menu-header {
      background-color: $secondary_color;
    }

    .sort-menu-text {

      span {
        color: $background_color;
      }
    }
  }
}

