@import '../../styles/theme.scss';
@import '../../styles/variables.scss';

.term-tile {
  padding: 0.75rem;
  margin: $element_flex_margin;
  background-color: $background_color;
  border: 2px solid $primary_color;
  min-width: 18rem;
  text-decoration: none;

  .term-tile-text {
    color: $primary_color;
    font-weight: 600;
  }

  &.highlighted {
    background-color: $primary_color;

    .term-tile-text {
      color: $background_color;
    }
  }

  &:hover {
    @extend .highlighted;
  }
}
