@import './variables.scss';

@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-left() {
  display: flex;
  align-items: center;
  justify-content: left;
}

@mixin font-face($name, $filename, $path, $weight: null, $style: null, $exts: null) {
  $src: null;

  $formats: (
    ttf: 'truetype'
  );

  @each $ext in $exts {
    $extmod: $ext;
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url('#{ $path }/#{ $filename }.#{ $ext }') format('#{ $format }'), comma);
  }

  @font-face {
    font-family: '#{ $name }';
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@function get-argument($args, $index) {
    @if (length($args) > $index) {
        @return nth($args, $index + 1);
    }
    @else {
        @return null;
    }
}

@function get-map-value($map, $key) {
    @if ($key and map-has-key($map, $key)) {
        @return map-get($map, $key);
    }
    @else {
        @return null;
    }
}
