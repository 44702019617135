@import '../../styles/theme.scss';
@import '../../styles/variables.scss';

.menu-link-container {
  text-decoration: none;

  .menu-link {
    height: 3rem;
    display: flex;
    color: $secondary_color;

    .link-icon {
      text-align: center;
      align-self: center;
      margin: 0 .7rem;
      font-size: $icon_width;
    }

    .link-text {
      align-self: center;
      color: $secondary_color;
    }
  }
}
