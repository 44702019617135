$primary_colors: (
  #ffb81c,
  #fbc75b,
  #fdd27e,
  #ffdd9e,
  #fee9bf
);

$secondary_colors: (
  #000000,
  #878787,
  #999999,
  #b1b1b1,
  #c3c3c3,
  #cfcfcf,
  #e0e0e0,
  #eaeaea,
);

$accent_colors: (
  #15b079,
  #0b6ec7,
  #ea0d2c
);

$primary_color: nth($primary_colors, 1);

$primary_color_hover: nth($primary_colors, 5);

$secondary_color: nth($secondary_colors, 1);

$claim_on_white_color: nth($secondary_colors, 3);

$claim_on_black_color: nth($secondary_colors, 4);

$accent_color_green: #15b079;

$accent_color_blue: #0b6ec7;

$accent_color_red: #ea0d2c;

$background_color: #ffffff;

$header_background: #f0f0f0;

h1 {
  font-size: 2.25rem !important;
  color: $primary_color !important;
}
