@import 'bootstrap/scss/bootstrap-grid.scss';
@import '../../styles/theme.scss';

$icon_square_side_size: 2.4rem;
$rounded_border_radius: 2rem;
$close_icon_square_side_size: 1.4rem;

.search-box-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .search-box {
    width: 35rem;
    height: 3rem;
    border-radius: $rounded_border_radius;
    color: $secondary_color;
    border: none;
    background-color: nth($secondary_colors, 8);
    padding-left: 1.3rem;
    padding-right: 3rem;
    font-size: .9rem;
    outline: none;

    &::-ms-clear {
      display: none;
    }
  }

  .icons {
    margin-left: -2.5rem;
    display: flex;
    width: $icon_square_side_size;

    .icon {
      padding: .5rem;
      font-size: 1.3rem;
      cursor: pointer;
      height: $icon_square_side_size;
      width: $icon_square_side_size;

      &.icon-Close {
        display: none;
        color: $claim_on_white_color;
        height: $close_icon_square_side_size;
        width: $close_icon_square_side_size;
        padding: 0;

        &.visible {
          display: block;
        }
      }

      &.icon-Search {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .search-box-wrapper {
    margin-right: .3rem;

    .search-box {
      width: 100%;
      padding-right: 5rem;
    }

    .icons {
      margin-left: -5.2rem;
      display: flex;
      justify-content: flex-end;
      width: $icon_square_side_size * 2;
      align-items: center;

      .icon {
        &.icon-Search {
          color: $primary_color;
          display: block;

          &:hover {
            background-color: nth($secondary_colors, 7);
            border-radius: $rounded_border_radius;
          }
        }
      }
    }
  }
}
