@import 'bootstrap/scss/bootstrap-grid.scss';
@import '../styles/theme.scss';
@import '../styles/variables.scss';

.menu-wrapper {
  transition: opacity .3s,
              visibility .3s,
              width .3s;

  &.initial {
    @include media-breakpoint-up(xl) {
      width: $menu_width;

      .link-text {
        visibility: inherit;
      }
    }

    @include media-breakpoint-between(sm, lg) {
      width: $menu_collapsed_width;

      .link-text {
        visibility: hidden;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 0 !important;

      .mobile-background {
        opacity: 0 !important;
        visibility: hidden !important;
      }

      .menu-link {
        visibility: hidden !important;
      }
    }
  }

  @include media-breakpoint-up(xs) {
    &:not(.initial) {
      &.visible {
        width: $menu_width !important;

        .link-text {
          visibility: inherit;
        }
      }

      &:not(.visible) {
        width: $menu_collapsed_width !important;

        .link-text {
          visibility: hidden;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 0;
    z-index: 3;

    &:not(.visible) {
      visibility: hidden;
      width: 0;

      .menu-link {
        visibility: hidden;
      }
    }

    &.visible {
      visibility: inherit;
      width: $menu_width;

      .mobile-background {
        opacity: .5;
        visibility: visible;
        position: fixed;
      }

      .menu-link {
        visibility: inherit;
      }
    }

    .mobile-background {
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      visibility: hidden;
      cursor: pointer;
      background-color: rgba(0, 0, 0, .8);
    }
  }

  .menu-container {
    width: inherit;
    height: calc(100vh - #{$header_height});
    background-color: nth($secondary_colors, 8);
    padding-top: 0.3rem;

    @include media-breakpoint-down(sm) {
      height: calc(100vh);
      position: relative;
      z-index: 3;
    }

    .menu {
      padding: 1rem 0;

      &.top {
        margin: 0 0.3rem;

        .menu-link {
          &:hover {
            font-weight: 600;

            .link-text {
              font-weight: 600;
            }
          }
        }
      }

      &.bottom {
        background-color: $secondary_color;

        .menu-link {
          &:hover {
            color: $claim_on_black_color;

            .link-text {
              color: $claim_on_black_color;
            }
          }
        }
      }
    }
  }
}
