@import '../../../styles/theme.scss';

.tag-wrapper {
  display: inline-block;
  margin: 0.1rem;
  background-color: nth($secondary_colors, 2);

  .tag-label {
    color: $background_color;
    margin: 0.3rem;
  }
}
