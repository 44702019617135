@import 'bootstrap/scss/bootstrap-grid.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/theme.scss';

.mobile-document-filters {
  display: none;

  .accordion-summary {
    height: 2rem;
    margin-bottom: $mobile_margin;
    border-bottom: 0.0625rem solid nth($secondary_colors, 3);
  }

  .icon-container {
    padding: 0;

    span {
      height: 1.5rem;
    }
  }
}

.document-filters {
  display: block;
}

.document-filters-wrapper {
  display: flex;
  flex-wrap: wrap;

  .taxonomy-selector-container,
  .refiner-wrapper {
    width: $refiner_width;
    min-width: $refiner_width;
    margin: $element_flex_margin;
  }
}

@include media-breakpoint-down(sm) {

  .mobile-document-filters {
    display: block;
    margin-bottom: $mobile_margin;
  }

  .document-filters {
    display: none;
  }

  .document-filters-wrapper {
    justify-content: space-between;
    margin-bottom: $mobile_margin;

    .taxonomy-selector-container,
    .refiner-wrapper {
      width: calc(50% - #{$mobile_margin});
      margin: 0 0 $mobile_margin;
      min-width: auto;
    }
  }
}
