@import 'bootstrap/scss/bootstrap-grid.scss';
@import './styles/variables.scss';

body {
  overflow: hidden;
}

.app-wrapper {
  flex: 1;
  overflow-x: auto;

  .container {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
}

.app-navigation {
  display: flex;
  overflow: hidden;
  height: calc(100vh - #{$header_height});

  @include media-breakpoint-down(sm) {
    height: calc(100vh - #{$header_mobile_height});
  }
}
