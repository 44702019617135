@import 'bootstrap/scss/bootstrap-grid.scss';
@import '../../../styles/theme.scss';

.sort-button-container {
  display: none;
  text-align: right;
  color: $primary_color;

  .icon-container {
    padding: 0.125rem 0.3125rem;
  }
}

@include media-breakpoint-down(sm) {

  .sort-button-container {
    display: block;
  }
}
